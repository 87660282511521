// React & Props
import React from "react";
import PropTypes from "prop-types";
// import { getLCP } from "web-vitals";

// Lattice Components
import Lattice from "lattice/lib/components/Lattice";
import LatticeDevLines from "lattice/lib/components/LatticeDevLines";

// Quilt Components
import Header from "quilt/lib/components/organisms/header";
import Footer from "quilt/lib/components/organisms/footer";
import Preload from "quilt/lib/components/functional/preload";

// Styles
import "quilt/src/styles/layout.scss";

import "quilt/src/styles/header.scss";
import {
  navHeight,
  navPencilbarHeight,
} from "quilt/src/styles/header-styles.module.scss";
import "quilt/src/styles/footer.scss";
import "../styles/alt-content.scss";

// Providers
import ContextProvider from "../provider/contextProvider";
import ShopifyContextProvider from "../provider/shopifyContextProvider";

// Internal Components
import StoreContext from "../context/storeContext";
import ShopifyContext from "../context/shopifyContext";
import BuffyTracking from "../components/functional/tracking";

// Hooks
// import useSiteMenus from "../hooks/useSiteMenus";

import CONSTANTS from "../constants";

const devLines =
  process.env.DEV_LINES && process.env.DEV_LINES.toLowerCase() === "true";
// const isPreview =
// process.env.PREVIEW && process.env.PREVIEW.toLowerCase() === "true";

const Layout = ({ children, pageContext }) => {
  // getLCP(console.log, true);
  // getCLS(console.log, true);
  let content = null;
  if (pageContext.layout === "page") {
    let headerColor = "dark";
    if (pageContext.page && pageContext.page.heroTextColor) {
      headerColor = pageContext.page.heroTextColor;
    } else if (pageContext.page && !pageContext.page.media) {
      headerColor = "light";
    }
    const headerInfo = {
      transparentHeader: true,
      headerMode: headerColor,
    };

    content = (
      <ContextProvider>
        <ShopifyContextProvider>
          <Preload />
          {process.env.DISABLE_TRACKING ? (
            <BuffyTracking
              id={process.env.FACEBOOK_PIXEL_ID}
              layout={pageContext.layout}
            />
          ) : null}
          <Lattice
            className={`buffy ${pageContext.layout} ${
              pageContext.page && pageContext.page.slug
                ? `${pageContext.layout}-${pageContext.page.slug}`
                : ""
            }`}
            hideOverflow={devLines}>
            {devLines ? <LatticeDevLines maxRows={1000} /> : null}
            <Header
              transparent={headerInfo.transparentHeader}
              menus={[]}
              color={headerInfo.headerMode || "dark"}
              layout={pageContext.layout}
              discounts={pageContext.discounts}
              cart={false}
              reviews={
                pageContext.productReviews && pageContext.productReviews.details
              }
              extras={{
                storeContext: StoreContext,
                shopifyContext: ShopifyContext,
                headerStyles: {
                  navHeight,
                  navPencilbarHeight,
                },
                ...CONSTANTS,
                domain: process.env.DOMAIN,
                OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
                PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
                FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
                DEV: process.env.DEV,
                KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
                SHOW_DISCOUNTS: process.env.SHOW_DISCOUNTS,
              }}
            />
            <main>{children}</main>
            <Footer
              email={false}
              menus={[]}
              extras={{
                OPTIMIZE_PREFIX: CONSTANTS.OPTIMIZE_PREFIX,
                storeContext: StoreContext,
                DEV: process.env.DEV,
                KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
              }}
            />
          </Lattice>
        </ShopifyContextProvider>
      </ContextProvider>
    );
  } else {
    // 404 page
    content = children;
  }
  return content;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({ layout: PropTypes.string }),
};

Layout.defaultProps = {
  pageContext: {},
};

export default Layout;
