import atob from "atob";
import format from "date-fns/format";

const decodeId = (id) => {
  let did = atob(id);
  did = did.substring(did.lastIndexOf("/") + 1, did.length);
  return did;
};

const getMicrositeLink = ({ page, allPages, prefix }) => {
  const fullPath = page.slug === prefix ? "" : page.slug;
  const parent = allPages.find(
    (ap) => ap.id === (page.parentPage && page.parentPage.id),
  );
  if (!parent || parent.slug === prefix) {
    return `/${fullPath}`;
  }
  return `${getMicrositeLink({ page: parent, allPages, prefix })}/${fullPath}`;
};

const getActiveColor = ({ activeVariant, productColors }) => {
  if (!activeVariant || !activeVariant.title) {
    return null;
  }
  const activeOpts = activeVariant.title.split(" / ");
  const activeProductColor = productColors.find((color) => {
    const colorName = color.node.shopifyColorOption.color;
    return activeOpts.includes(colorName);
  });
  return activeProductColor && activeProductColor.node;
};

const findProductBundle = (activeVariant, product, bundles, quantity) => {
  let bundleDiscount = null;
  const summaryList = [];
  // Collect summary array independent of quantity
  bundles.map((bundle) => {
    const decodedProductId = decodeId(product.shopifyProduct.shopifyId);
    const decodedVariantId = decodeId(activeVariant.id);
    if (bundle.product_id === decodedProductId) {
      bundle.items.find((item) => {
        const pidMatch =
          (item.product_ids && item.product_ids.includes(decodedProductId)) ||
          !item.product_ids;
        const vidMatch =
          (item.variant_ids && item.variant_ids.includes(decodedVariantId)) ||
          !item.variant_ids;
        if (pidMatch && vidMatch) {
          summaryList.push(bundle.summary);
        }
        return false;
      });
    }
    return false;
  });
  const validBundle = bundles.find((bundle) => {
    const decodedProductId = decodeId(product.shopifyProduct.shopifyId);
    const decodedVariantId = decodeId(activeVariant.id);
    if (bundle.product_id === decodedProductId) {
      const invalidBundleItem = bundle.items.find((item) => {
        const pidMatch =
          (item.product_ids && item.product_ids.includes(decodedProductId)) ||
          !item.product_ids;
        const vidMatch =
          (item.variant_ids && item.variant_ids.includes(decodedVariantId)) ||
          !item.variant_ids;
        const qMatch = item.quantity === quantity;
        if (pidMatch && vidMatch && qMatch) {
          bundleDiscount = {
            discountType: item.discount_type,
            discountValue: item.discount_value,
          };
        } else {
          bundleDiscount = null;
        }
        return !pidMatch || !vidMatch || !qMatch;
      });
      return !invalidBundleItem;
    }
    return false;
  });
  if (validBundle) {
    return {
      bundle: validBundle,
      discount: bundleDiscount,
      quantitySummaryList: summaryList.sort(),
    };
  }
  if (summaryList.length) {
    return {
      quantitySummaryList: summaryList.sort(),
    };
  }
  return null;
};

const hasOptimizeContent = (page) =>
  page.alternativeContent &&
  page.alternativeContent.find(
    (ac) => ac.googleOptimizeExperimentId && ac.googleOptimizeExperimentVariant,
  )
    ? true
    : null;

const validDiscount = (discount) =>
  discount &&
  discount.status === "ACTIVE" &&
  (!discount.endsAt ||
    format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx") < discount.endsAt) &&
  (!discount.usageLimit || discount.asyncUsageCount < discount.usageLimit);

const caseInsensitiveEqual = (a, b) =>
  a === b || (a && b && a.toLowerCase() === b.toLowerCase());

const isGiftCardProduct = (product) =>
  product &&
  ((product.tags && product.tags.includes("gift-card")) ||
    (product.shopifyProduct &&
      product.shopifyProduct.tags.includes("gift-card")));

const productHasNoInventory = (product) =>
  product &&
  ((product.tags && product.tags.includes("gift-card")) ||
    (product.shopifyProduct &&
      product.shopifyProduct.tags.includes("gift-card")));

const calcDiscountPrice = (obj) => {
  const {
    bundleInfo,
    price,
    lineItems,
    product,
    variant,
    quantity,
    discounts,
    params,
  } = obj;
  const showDiscounts =
    process.env.SHOW_DISCOUNTS &&
    process.env.SHOW_DISCOUNTS.toLowerCase() === "true";
  const info = {
    price,
    valid: true,
  };
  if (productHasNoInventory(product)) {
    info.valid = false;
    return info;
  }
  // Only support percentage discounts on PDP/PLP level
  if (bundleInfo) {
    info.bundleQuantitySummaryList = bundleInfo.quantitySummaryList;
  }
  if (bundleInfo && bundleInfo.bundle) {
    // const nameSplit = bundleInfo.bundle.name.split(" - ");
    info.bundleDiscountName = bundleInfo.bundle.name;
    // nameSplit.length > 0 ? nameSplit[1] : nameSplit[0];
    // info.type = "bundle";
    // info.color = "green";
    if (bundleInfo.discount.discountType === "fixed") {
      info.bundlePrice = Math.trunc(price - bundleInfo.discount.discountValue);
      info.newPrice = info.bundlePrice;
      info.bundleSummary = `$${bundleInfo.discount.discountValue} off`;
    }
    if (bundleInfo.discount.discountType === "percent") {
      info.bundlePrice = (
        price -
        price * (bundleInfo.discount.discountValue / 100)
      ).toFixed(2);
      info.newPrice = info.bundlePrice;
      info.bundleSummary = `${bundleInfo.discount.discountValue}% off`;
    }
  }
  if (discounts) {
    const bundlePrice = info.newPrice || price;
    const discountMatch = discounts.find(
      (d) =>
        d.node.shopifyDiscount &&
        d.node.shopifyDiscount.title === params.discount,
    );
    if (discountMatch) {
      info.discountName = discountMatch.node.displayTitle || params.discount;
      // info.type = "code";
      info.color = discountMatch.node.badgeColor || "purple";

      // Handle discount codes for entire order
      if (discountMatch.node.shopifyDiscount.customerGets.items.allItems) {
        // Handle fixed * DISABLED *
        if (discountMatch.node.shopifyDiscount.customerGets.value.amount) {
          info.newPrice = Math.trunc(
            bundlePrice -
              discountMatch.node.shopifyDiscount.customerGets.value.amount
                .amount,
          );
          // info.newPrice = price;
          info.summary = discountMatch.node.shopifyDiscount.shortSummary;
          info.skipDisplay = true;
        }

        // Handle percentage
        if (discountMatch.node.shopifyDiscount.customerGets.value.percentage) {
          info.newPrice = (
            bundlePrice -
            bundlePrice *
              discountMatch.node.shopifyDiscount.customerGets.value.percentage
          ).toFixed(2);
          info.summary = `${discountMatch.node.shopifyDiscount.shortSummary}`;
        }

        // Handle Minimum Requirements
        if (discountMatch.node.shopifyDiscount.minimumRequirement) {
          if (
            discountMatch.node.shopifyDiscount.minimumRequirement
              .greaterThanOrEqualToQuantity &&
            parseInt(
              discountMatch.node.shopifyDiscount.minimumRequirement
                .greaterThanOrEqualToQuantity,
              10,
            ) >
              lineItems.length + quantity
          ) {
            info.valid = false;
          } else if (
            discountMatch.node.shopifyDiscount.minimumRequirement
              .greaterThanOrEqualToSubtotal &&
            parseFloat(
              discountMatch.node.shopifyDiscount.minimumRequirement
                .greaterThanOrEqualToSubtotal.amount,
            ) > price
          ) {
            info.valid = false;
          }
        }
      } else if (
        discountMatch.node.shopifyDiscount.customerGets.items.products.edges
          .length
      ) {
        // Handle discounts for specific products
        const discountProductMatch =
          discountMatch.node.shopifyDiscount.customerGets.items.products.edges.find(
            (p) =>
              ((product.shopifyProduct && product.shopifyProduct.handle) ||
                product.handle) === p.node.handle,
          );
        if (discountProductMatch) {
          // Handle fixed * DISABLED *
          if (discountMatch.node.shopifyDiscount.customerGets.value.amount) {
            info.newPrice = Math.trunc(
              bundlePrice -
                discountMatch.node.shopifyDiscount.customerGets.value.amount
                  .amount,
            );
            // info.newPrice = price;
            info.summary = `$${Math.trunc(
              discountMatch.node.shopifyDiscount.customerGets.value.amount
                .amount,
            )} off entire order`;
            info.skipDisplay = true;
          }

          // Handle percentage
          if (
            discountMatch.node.shopifyDiscount.customerGets.value.percentage
          ) {
            info.newPrice = (
              bundlePrice -
              bundlePrice *
                discountMatch.node.shopifyDiscount.customerGets.value.percentage
            ).toFixed(2);
            info.summary = `${parseInt(
              discountMatch.node.shopifyDiscount.customerGets.value.percentage *
                100,
              10,
            )}% off`;
          }

          // Handle Minimum Requirements
          if (discountMatch.node.shopifyDiscount.minimumRequirement) {
            if (
              discountMatch.node.shopifyDiscount.minimumRequirement
                .greaterThanOrEqualToQuantity &&
              parseInt(
                discountMatch.node.shopifyDiscount.minimumRequirement
                  .greaterThanOrEqualToQuantity,
                10,
              ) > quantity
            ) {
              info.valid = false;
            } else if (
              discountMatch.node.shopifyDiscount.minimumRequirement
                .greaterThanOrEqualToSubtotal &&
              parseFloat(
                discountMatch.node.shopifyDiscount.minimumRequirement
                  .greaterThanOrEqualToSubtotal.amount,
              ) > price
            ) {
              info.valid = false;
            }
          }
        } else {
          info.valid = false;
        }
      } else if (
        variant &&
        discountMatch.node.shopifyDiscount.customerGets.items.productVariants
          .edges.length
      ) {
        // Handle discounts for specific product variants
        const discountVariantMatch =
          discountMatch.node.shopifyDiscount.customerGets.items.productVariants.edges.find(
            (p) => p.node.id === atob(variant.id),
          );
        if (discountVariantMatch) {
          // Handle fixed
          if (discountMatch.node.shopifyDiscount.customerGets.value.amount) {
            info.newPrice = Math.trunc(
              bundlePrice -
                discountMatch.node.shopifyDiscount.customerGets.value.amount
                  .amount,
            );
            info.summary = `$${Math.trunc(
              discountMatch.node.shopifyDiscount.customerGets.value.amount
                .amount,
            )} off`;
            info.skipDisplay = true;
          }
          // Handle percentage
          if (
            discountMatch.node.shopifyDiscount.customerGets.value.percentage
          ) {
            info.newPrice = (
              bundlePrice -
              bundlePrice *
                discountMatch.node.shopifyDiscount.customerGets.value.percentage
            ).toFixed(2);
            info.summary = `${parseInt(
              discountMatch.node.shopifyDiscount.customerGets.value.percentage *
                100,
              10,
            )}% off`;
          }

          // Handle Minimum Requirements
          if (discountMatch.node.shopifyDiscount.minimumRequirement) {
            if (
              discountMatch.node.shopifyDiscount.minimumRequirement
                .greaterThanOrEqualToQuantity &&
              parseInt(
                discountMatch.node.shopifyDiscount.minimumRequirement
                  .greaterThanOrEqualToQuantity,
                10,
              ) > quantity
            ) {
              info.valid = false;
            } else if (
              discountMatch.node.shopifyDiscount.minimumRequirement
                .greaterThanOrEqualToSubtotal &&
              parseFloat(
                discountMatch.node.shopifyDiscount.minimumRequirement
                  .greaterThanOrEqualToSubtotal.amount,
              ) > price
            ) {
              info.valid = false;
            }
          }
        } else {
          info.valid = false;
        }
      }
    } else if (!info.bundlePrice) {
      info.valid = false;
    }
  }
  // console.log("info result", info);
  if (!showDiscounts) {
    info.skipDisplay = true;
  }
  return info;
};

const shorthandPrice = (price) =>
  Math.trunc(price) === Math.ceil(price) ? Math.trunc(price) : price.toFixed(2);

const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// Opt-out function
const gaOptout = () => {
  const isClient = typeof window === "object" && typeof document === "object";
  const disableStr = `ga-disable-${process.env.GOOGLE_ANALYTICS_ID}`;
  if (isClient) {
    document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    // Remove cookie
    // document.cookie = `${disableStr}=true; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};

// Check Opt-out function
const gaOptoutCheck = () => {
  const isClient = typeof window === "object" && typeof document === "object";
  const disableStr = `ga-disable-${process.env.GOOGLE_ANALYTICS_ID}`;
  const name = `${disableStr}=`;
  let found = null;
  if (isClient) {
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        found = disableStr;
      }
    }
  }
  return found;
};

export default {
  decodeId,
  findProductBundle,
  getActiveColor,
  hasOptimizeContent,
  caseInsensitiveEqual,
  validDiscount,
  calcDiscountPrice,
  shorthandPrice,
  isGiftCardProduct,
  emailIsValid,
  gaOptout,
  gaOptoutCheck,
  getMicrositeLink,
};
