import React from "react";
import PropTypes from "prop-types";

// Styles
import MediaBlock from "quilt/lib/components/organisms/media-block";
import Utils from "quilt/lib/utils";

const Page404 = ({ text }) => {
  const block404 = {
    id: "block404",
    title: "404 Hero",
    ctaText: "Back To Home",
    classNames: null,
    useGutters: null,
    backgroundColor: "green",
    ctaColor: "orange",
    ctaUrl: null,
    ctaContent: {
      id: "home",
      slug: "",
      sys: {
        contentType: {
          sys: {
            id: "page",
          },
        },
      },
    },
    shortText: text || "Oops, this page doesn't exist.",
    longText: null,
    image: null,
    supportingImage: null,
    video: null,
    imagePosition: null,
    textColor: "light",
    type: "hero",
  };

  return (
    <MediaBlock
      type={block404.type}
      className={block404.classNames}
      useTitle
      {...Utils.getMediaBlockProps(block404)}
    />
  );
};

Page404.propTypes = {
  text: PropTypes.string,
};

Page404.defaultProps = {
  text: null,
};

export default Page404;
