/* global fbq,_learnq */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Axios from "axios";
import PublicIP from "public-ip";

import Utils from "quilt/lib/utils";

import StoreContext from "../../context/storeContext";
import ShopifyContext from "../../context/shopifyContext";

import BuffyUtils from "../../utils";
import CONSTANTS from "../../constants";

// Hooks
import usePrevious from "../../hooks/usePrevious";

const forceTracking =
  process.env.FORCE_TRACKING &&
  process.env.FORCE_TRACKING.toLowerCase() === "true";
const optOutCookie = BuffyUtils.gaOptoutCheck();
const isTracking =
  (process.env.NODE_ENV === `production` || forceTracking) && !optOutCookie;
const isDev = process.env.DEV && process.env.DEV.toLowerCase() === "true";

const BuffyTracking = ({ id, layout }) => {
  const {
    store: { currentProduct, currentVariant, currentQuantity, oosSuccess },
    updateOosSuccess,
  } = useContext(StoreContext);

  const {
    store: { adding },
  } = useContext(ShopifyContext);

  const [pixelLoaded, setPixelLoaded] = useState();

  const FBServerTrack = async ({ eventName, eventId, eventTime, cb }) => {
    const publicIp = await PublicIP.v4();
    const serverObj = {
      data: [
        {
          event_name: eventName,
          ...(eventId ? { event_id: eventId } : undefined),
          event_time: eventTime,
          user_data: {
            client_ip_address: publicIp,
            client_user_agent: navigator.userAgent,
          },
          custom_data: {
            content_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
            content_ids: `${BuffyUtils.decodeId(
              currentProduct.shopifyProduct.shopifyId,
            )}`,
            content_type: "product_group",
            value: `${currentVariant.price}`,
            currency: CONSTANTS.CURRENCY,
            ...(currentQuantity ? { num_items: currentQuantity } : undefined),
          },
          event_source_url: window.location.href,
          action_source: "website",
          data_processing_options: ["LDU"],
          data_processing_options_country: 0,
          data_processing_options_state: 0,
        },
      ],
      access_token: process.env.FACEBOOK_ACCESS_TOKEN,
      ...(isDev ? { test_event_code: "TEST15623" } : undefined),
    };
    try {
      await Axios.post(
        `https://graph.facebook.com/v10.0/${process.env.FACEBOOK_PIXEL_ID}/events`,
        serverObj,
      );
      if (cb) {
        cb();
      }
    } catch (e) {
      console.log("facebook conversions api error", e);
    }
  };

  // FB View Product Page Event
  const prevCurrentVariant = usePrevious(currentVariant);
  useEffect(() => {
    const eventTime = Math.floor(Date.now() / 1000);
    if (
      pixelLoaded &&
      currentProduct &&
      currentVariant &&
      (!prevCurrentVariant || currentVariant.id !== prevCurrentVariant.id) &&
      layout === "product"
    ) {
      // ContentView
      // console.log("product pageview", currentVariant);
      fbq(
        "track",
        "ViewContent",
        {
          content_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
          content_ids: `${BuffyUtils.decodeId(
            currentProduct.shopifyProduct.shopifyId,
          )}`,
          content_type: "product_group",
          value: `${currentVariant.price}`,
          currency: CONSTANTS.CURRENCY,
          ...(currentQuantity ? { num_items: currentQuantity } : undefined),
        },
        {
          eventID: `view_${BuffyUtils.decodeId(
            currentProduct.shopifyProduct.shopifyId,
          )}_${eventTime}`,
        },
      );
    }

    // Send Server event via Conversions API
    if (
      isTracking &&
      typeof window !== `undefined` &&
      currentProduct &&
      currentVariant &&
      (!prevCurrentVariant || currentVariant.id !== prevCurrentVariant.id) &&
      layout === "product"
    ) {
      FBServerTrack({
        eventName: "ViewContent",
        eventId: `view_${BuffyUtils.decodeId(
          currentProduct.shopifyProduct.shopifyId,
        )}_${eventTime}`,
        eventTime,
      });
    }
  }, [pixelLoaded, currentProduct, currentVariant, currentQuantity]);

  // Klaviyo View Product Page Event
  useEffect(() => {
    if (currentProduct && layout === "product") {
      if (isTracking && typeof _learnq === `object`) {
        const item = {
          Name: currentProduct.title,
          ProductID: BuffyUtils.decodeId(
            currentProduct.shopifyProduct.shopifyId,
          ),
          Tags: currentProduct.shopifyProduct.tags,
          ImageURL: `https:${currentProduct.mainImage}`,
          URL: window.location.href,
          Brand: currentProduct.shopifyProduct.vendor,
          Price: Utils.getFromPrice(
            currentProduct.shopifyProduct.variants.edges,
          ),
        };
        _learnq.push(["track", "Viewed Product (react)", item]);
        _learnq.push([
          "trackViewedItem",
          {
            Title: item.Name,
            ItemId: item.ProductID,
            Tags: item.Tags,
            // ImageUrl: item.ImageURL,
            Url: item.URL,
            Metadata: {
              Brand: item.Brand,
              Price: item.Price,
            },
          },
        ]);
      }
    }
  }, [currentProduct]);

  // Add To Cart event
  const prevAdding = usePrevious(adding);
  useEffect(() => {
    if (prevAdding && !adding) {
      const eventTime = Math.floor(Date.now() / 1000);
      const eventId = `atc_${BuffyUtils.decodeId(
        currentProduct.shopifyProduct.shopifyId,
      )}_${eventTime}`;
      if (pixelLoaded) {
        fbq(
          "track",
          "AddToCart",
          {
            content_name: `${currentProduct.shopifyProduct.title} - ${currentVariant.title}`,
            content_ids: `${BuffyUtils.decodeId(
              currentProduct.shopifyProduct.shopifyId,
            )}`,
            content_type: "product_group",
            // content_variant_title: `${currentVariant.title}`,
            value: `${currentVariant.price}`,
            currency: CONSTANTS.CURRENCY,
            num_items: currentQuantity,
          },
          { eventID: eventId },
        );
      }

      // Send Server event via Conversions API
      if (isTracking && typeof window !== `undefined`) {
        FBServerTrack({
          eventName: "AddToCart",
          eventId,
          eventTime,
          cb: () => {
            window.location.href = "/cart";
          },
        });
      } else {
        // redirect to cart even if tracking is disabled
        window.location.href = "/cart";
      }

      if (isTracking && typeof _learnq === `object`) {
        const item = {
          Name: currentProduct.shopifyProduct.title,
          ProductID: BuffyUtils.decodeId(
            currentProduct.shopifyProduct.shopifyId,
          ),
          Tags: currentProduct.shopifyProduct.tags,
          ImageURL: `https:${currentProduct.mainImage}`,
          URL: window.location.href,
          Brand: currentProduct.shopifyProduct.vendor,
          Price: Utils.getFromPrice(
            currentProduct.shopifyProduct.variants.edges,
          ),
        };
        _learnq.push(["track", "Added to Cart (react)", item]);
      }
    }
  }, [adding]);

  // Klaviyo Subscribe to OOS event
  useEffect(() => {
    if (oosSuccess) {
      if (isTracking && typeof _learnq === `object`) {
        const optionsMap = Utils.mapVariantOptions(
          currentProduct,
          currentVariant,
        );
        const variantItem = {
          VariantId: BuffyUtils.decodeId(currentVariant.id),
          Price: currentVariant.price,
          Quantity: currentQuantity || 1,
          ...(optionsMap.size ? { Size: optionsMap.size } : undefined),
          ...(optionsMap.color ? { Color: optionsMap.color } : undefined),
          ...(optionsMap.firmness
            ? { Firmness: optionsMap.firmness }
            : undefined),
          ...(optionsMap.value ? { Value: optionsMap.value } : undefined),
          Sku: currentVariant.sku,
          // Image: activeVariant.image,
          Url: `${window.location.origin}${window.location.pathname}${
            Object.entries(optionsMap).length ? "?" : ""
          }${Object.entries(optionsMap)
            .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
            .join("&")}`,
          ProductName: currentProduct.shopifyProduct.title,
        };
        _learnq.push([
          "identify",
          {
            $email: oosSuccess,
          },
        ]);
        _learnq.push(["track", "Subscribe To OOS (react, embed)", variantItem]);
        updateOosSuccess(null);
      }
    }
  }, [oosSuccess]);

  if (isTracking) {
    return (
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
              n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
              document,'script','/fbevents.js');
              fbq.disablePushState = true;
              fbq('dataProcessingOptions', ['LDU'], 0, 0);
              fbq('init', '${id}'); // Insert your pixel ID here.
              fbq("track", "PageView");`,
          },
        ]}
        onChangeClientState={() => {
          if (typeof fbq === `function`) {
            setPixelLoaded(true);
          }
        }}
      />
    );
  }
  if (optOutCookie) {
    return (
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `window["${optOutCookie}"] = true;`,
          },
        ]}
      />
    );
  }
  return null;
};

BuffyTracking.propTypes = {
  id: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
};

export default BuffyTracking;
