/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { format, addYears, addDays, addMonths } from "date-fns";
import { useStaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

import Utils from "quilt/lib/utils";
import CONSTANTS from "../../constants";

export const PureSEO = ({
  site,
  description,
  lang,
  meta,
  title,
  image,
  product,
  variant,
  reviews,
  color,
  params,
}) => {
  const metaDescription = description || site.siteMetadata.description;
  // Sort by review score and get first
  const sortedReviews =
    reviews && reviews.list.sort((a, b) => (a.score > b.score ? 1 : -1));

  const validDate = (opts) => {
    const newDate = addDays(
      addMonths(
        addYears(new Date(), (opts && opts.years) || 0),
        (opts && opts.months) || 0,
      ),
      (opts && opts.days) || 0,
    );
    return format(newDate, "yyyy-MM-dd");
  };
  const hasPDPParams = () =>
    params && (params.size || params.firmness || params.color || params.value);
  const mapParams = () => {
    const results = [];
    Object.entries(params).map(([k, v]) => {
      if (CONSTANTS.PDP_PARAMS.includes(k) && v) {
        results.push(`${k}=${encodeURIComponent(v)}`);
      }
      return true;
    });
    return `?${results.join("&")}`;
  };
  // If there are params, wait until they match the variant before displaying metadata
  let displayMetaProduct = product && variant;
  if (displayMetaProduct) {
    const paramsLoaded =
      params &&
      !CONSTANTS.PDP_PARAMS.find((p) => !Object.keys(params).includes(p));
    const variantMap = Utils.mapVariantOptions(product, variant);
    const variantMatchesParams = !CONSTANTS.PDP_PARAMS.find(
      (p) => params[p] !== variantMap[p] && params[p] !== undefined,
    );
    displayMetaProduct =
      displayMetaProduct && paramsLoaded && variantMatchesParams;
  }
  const metaProduct = displayMetaProduct
    ? {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: `${title}${
          hasPDPParams() && variant ? ` - ${variant.title}` : ""
        } | ${site.siteMetadata.title}`,
        image: product.sliderImages
          ? product.sliderImages.map((simg, sindex) => {
              let sliderBackground = getSrc(simg);
              if (color) {
                if (color.colorImages && color.colorImages.length > sindex) {
                  sliderBackground = getSrc(color.colorImages[sindex]);
                } else if (sindex === 0) {
                  sliderBackground = getSrc(color.colorImage);
                }
              }
              return sliderBackground;
            })
          : [],
        description: metaDescription,
        sku: `shopify_US_${Utils.decodeId(
          product.shopifyProduct.shopifyId,
        )}_${Utils.decodeId(variant.id)}`,
        mpn: variant.sku,
        brand: {
          "@type": "Brand",
          name: site.siteMetadata.title,
        },
        ...(sortedReviews && sortedReviews.length
          ? {
              review: {
                "@type": "Review",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: sortedReviews[0].score,
                  bestRating: 5,
                },
                author: {
                  "@type": "Person",
                  name: sortedReviews[0].user.display_name,
                },
              },
            }
          : undefined),
        ...(sortedReviews && sortedReviews.length
          ? {
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: reviews.details.score,
                reviewCount: reviews.details.numReviews,
              },
            }
          : undefined),
        offers: {
          "@type": "Offer",
          url: `${site.siteMetadata.siteUrl}/${product.shopifyProduct.handle}${
            hasPDPParams() ? mapParams() : ""
          }`,
          itemCondition: "http://schema.org/NewCondition",
          priceCurrency: CONSTANTS.CURRENCY,
          price: variant.price,
          priceValidUntil: validDate({ years: 1 }),
          availability: Utils.isAvailable(
            variant,
            CONSTANTS.OOS_QUANTITY,
            product,
          )
            ? "https://schema.org/InStock"
            : "https://schema.org/OutOfStock",
        },
      }
    : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title}${
        hasPDPParams() && variant ? ` - ${variant.title}` : ""
      }`}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:title",
          content: `${title}${
            hasPDPParams() && variant ? ` - ${variant.title}` : ""
          } | ${site.siteMetadata.title}`,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:image",
          content: image || CONSTANTS.BUFFY_LOGO_URL,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        // {
        //   name: "twitter:creator",
        //   content: site.siteMetadata.author,
        // },
        {
          name: "twitter:title",
          content: `${title} | ${site.siteMetadata.title}`,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
      ].concat(meta)}
      defer={false}
      script={
        metaProduct
          ? [
              {
                type: "application/ld+json",
                innerHTML: JSON.stringify(metaProduct),
              },
            ]
          : []
      }
    />
  );
};

const SEO = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  return <PureSEO {...props} site={site} />;
};

PureSEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
  image: null,
  site: {
    siteMetadata: {
      title: "Buffy",
      siteUrl: "https://buffy.co",
    },
  },
  product: null,
  variant: null,
  reviews: null,
  color: null,
  params: null,
};

PureSEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      author: PropTypes.string,
      siteUrl: PropTypes.string,
    }),
  }),
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shopifyProduct: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool,
        PropTypes.array,
      ]),
    ).isRequired,
    sliderImages: PropTypes.arrayOf(
      PropTypes.shape({
        gatsbyImageData: PropTypes.objectOf(PropTypes.any),
      }),
    ),
  }),
  variant: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    availableForSale: PropTypes.bool,
    quantityAvailable: PropTypes.number,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sku: PropTypes.string,
  }),
  reviews: PropTypes.shape({
    details: PropTypes.objectOf(PropTypes.any),
    list: PropTypes.arrayOf(PropTypes.any),
  }),
  color: PropTypes.shape({
    colorImages: PropTypes.arrayOf(
      PropTypes.shape({
        gatsbyImageData: PropTypes.objectOf(PropTypes.any),
      }),
    ),
    colorImage: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any),
    }),
  }),
  params: PropTypes.shape({
    size: PropTypes.string,
    color: PropTypes.string,
    firmness: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default SEO;
