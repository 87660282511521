import React from "react";
import PropTypes from "prop-types";
import { getSrc } from "gatsby-plugin-image";
// import { OutboundLink } from "gatsby-plugin-google-analytics";

// Gatsby
import { useStaticQuery, graphql } from "gatsby";

// Quilt Components
import MediaCarousel from "quilt/lib/components/organisms/media-carousel";
import MediaBlock from "quilt/lib/components/organisms/media-block";

// Utils
// import Utils from "quilt/lib/utils";

// Internal Components
import SEO from "../components/functional/seo";

// import CONSTANTS from "../constants";
import BuffyUtils from "../utils";

// Styles
import "quilt/src/styles/page.scss";
import "../styles/mission.scss";
import "../styles/holiday.scss";

const Page = ({ pageContext }) => {
  const pageProps = pageContext.page;
  const { allPages } = pageContext;

  const data = useStaticQuery(graphql`
    query {
      defaultImage: file(relativePath: { eq: "dandi-default.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, width: 1920)
        }
      }
      site {
        pathPrefix
      }
    }
  `);

  const seo = (
    <>
      <SEO
        title={pageProps.title}
        description={pageProps.description && pageProps.description.description}
        image={
          (pageProps.media && `https:${getSrc(pageProps.media[0])}`) || null
        }
      />
    </>
  );
  const heroProps = pageProps.media
    ? { color: pageProps.heroTextColor }
    : { backgroundColor: "eucalyptus", color: "light" };
  const contentWithDate =
    pageProps.date && pageProps.content
      ? `<h6>${pageProps.date}</h6>${pageProps.content.childMarkdownRemark.html}`
      : pageProps.content && pageProps.content.childMarkdownRemark.html;

  return (
    <>
      {seo}
      <MediaBlock
        type="hero"
        id={`hero-${pageProps.id}`}
        title={pageProps.title}
        shortText={pageProps.displayTitle || pageProps.title}
        // longText={pageProps.description && pageProps.description.description}
        image={pageProps.media && pageProps.media[0]}
        props={heroProps}
      />
      {pageProps.content ? (
        <MediaBlock
          type="text"
          id={`content-${pageProps.id}`}
          longText={pageProps.content && pageProps.content.content}
          props={{
            longHtml: contentWithDate,
          }}
        />
      ) : null}
      {pageProps.content && pageProps.relatedPages ? (
        <MediaCarousel
          title="Related Pages"
          useSlider={false}
          key={`related-${pageProps.id}`}
          className="related-block__carousel">
          {pageProps.relatedPages.map((relatedPage) => (
            <MediaBlock
              key={relatedPage.id}
              className=""
              type="related-block"
              id={relatedPage.id}
              title={relatedPage.title}
              shortText={relatedPage.displayTitle || relatedPage.title}
              longText={
                relatedPage.description && relatedPage.description.description
              }
              image={
                (relatedPage.media && relatedPage.media[0]) || {
                  ...data.defaultImage.childImageSharp,
                  description:
                    "Diverse group of people holding flowers and a dog, each wrapped in sheets and comforters.",
                }
              }
              grid={false}
              props={{
                imagePosition: "left",
                longHtml:
                  relatedPage.description &&
                  relatedPage.description.childMarkdownRemark.html,
                useGutters: null,
                cta: {
                  text: relatedPage.displayTitle || relatedPage.title,
                  label: relatedPage.displayTitle || relatedPage.title,
                  route: BuffyUtils.getMicrositeLink({
                    page: relatedPage,
                    allPages,
                    prefix: data.site.pathPrefix.slice(1),
                  }),
                },
              }}
            />
          ))}
        </MediaCarousel>
      ) : (
        pageProps.relatedPages &&
        pageProps.relatedPages.map((relatedPage) => (
          <MediaBlock
            key={relatedPage.id}
            type="guide-item"
            id={relatedPage.id}
            title={relatedPage.title}
            shortText={relatedPage.displayTitle || relatedPage.title}
            longText={
              relatedPage.description && relatedPage.description.description
            }
            image={
              (relatedPage.media && relatedPage.media[0]) || {
                ...data.defaultImage.childImageSharp,
                description:
                  "Diverse group of people holding flowers and a dog, each wrapped in sheets and comforters.",
              }
            }
            props={{
              imagePosition: "left",
              useGutters: null,
              cta: {
                text: relatedPage.displayTitle || relatedPage.title,
                // label: relatedPage.displayTitle || relatedPage.title,
                color: "buffyOrange",
                route: BuffyUtils.getMicrositeLink({
                  page: relatedPage,
                  allPages,
                  prefix: data.site.pathPrefix.slice(1),
                }),
              },
            }}
          />
        ))
      )}
    </>
  );
};

Page.propTypes = {
  pageContext: PropTypes.shape({
    layout: PropTypes.string,
    id: PropTypes.string,
    page: PropTypes.objectOf(PropTypes.any),
    allPages: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, slug: PropTypes.string }),
    ),
    colors: PropTypes.arrayOf(
      PropTypes.shape({ node: PropTypes.objectOf(PropTypes.any) }),
    ),
    discounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
};

Page.defaultProps = {
  pageContext: { layout: "page", id: "home", page: {} },
};

export default Page;
