// import "lattice/src/lattice.scss";
// import "lattice/lib/lattice.js";

function supportsResizeObserver() {
  return (
    "ResizeObserver" in global &&
    "ResizeObserverEntry" in global &&
    "contentRect" in ResizeObserverEntry.prototype
  );
}

function getPathname() {
  let pathname = null;
  if (typeof window !== "undefined") {
    // Remove closing / of pathname if exists
    pathname = window.location.pathname;
    if (pathname.length > 1 && pathname[pathname.length - 1] === "/") {
      pathname = pathname.slice(0, pathname.length - 1);
    } else if (!pathname.length) {
      pathname = "/";
    }
  }
  return pathname;
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
  if (!supportsResizeObserver()) {
    import("resize-observer-polyfill");
    console.log(`# ResizeObserver is polyfilled!`);
  }

  // Add page visit time to local storage and refresh if
  // more recent service worker update occurred
  // if (typeof window !== "undefined") {
  //   const swUpdate = JSON.parse(window.localStorage.getItem("swUpdate")) || {
  //     pages: {},
  //   };
  //   // Remove closing / of pathname if exists
  //   const pathname = getPathname();
  //   const pageLastUpdated = swUpdate.pages[pathname];
  //   swUpdate.pages[pathname] = Date.now();
  //   window.localStorage.setItem("swUpdate", JSON.stringify(swUpdate));
  //   if (
  //     swUpdate.lastUpdated &&
  //     (!pageLastUpdated ||
  //       (pageLastUpdated && pageLastUpdated < swUpdate.lastUpdated))
  //   ) {
  //     window.location.reload(true);
  //   }
  // }
};

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  // Only get curr position if we are not changing options
  if (
    !prevRouterProps ||
    (prevRouterProps &&
      routerProps.location.pathname !== prevRouterProps.location.pathname)
  ) {
    const currentPosition = getSavedScrollPosition(routerProps.location);
    window.scrollTo(...(currentPosition || [0, 0]));
  }
  return false;
};

// export const onServiceWorkerUpdateReady = () => {
//   if (typeof window !== "undefined") {
//     // Update local storage with sw update timestamp
//     const swUpdate = JSON.parse(window.localStorage.getItem("swUpdate")) || {
//       pages: {},
//     };
//     const now = Date.now();
//     swUpdate.lastUpdated = now;
//     // Remove closing / of pathname if exists
//     const pathname = getPathname();
//     swUpdate.pages[pathname] = now;
//     window.localStorage.setItem("swUpdate", JSON.stringify(swUpdate));
//     // Reload
//     window.location.reload(true);
//   }
// };
