import React, { useState, useEffect } from "react";
// import Client from "shopify-buy";
import { buildClient } from "shopify-buy/index.unoptimized.umd";
import PropTypes from "prop-types";

import ShopifyContext from "../context/shopifyContext";

// import usePrevious from "../hooks/usePrevious";

const isE2E =
  process.env.E2E_TEST && process.env.E2E_TEST.toLowerCase() === "true";

const client = buildClient({
  storefrontAccessToken: isE2E
    ? process.env.E2E_SHOPIFY_ACCESS_TOKEN
    : process.env.SHOPIFY_ACCESS_TOKEN,
  domain: `${
    isE2E ? process.env.E2E_SHOPIFY_SHOP_NAME : process.env.SHOPIFY_SHOP_NAME
  }.myshopify.com`,
});

const ShopifyContextProvider = ({ children, mock }) => {
  const initialStoreState = {
    client: (mock && mock.client) || client,
    adding: false,
    checkout: { lineItems: [] },
  };
  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== "undefined";
      const existingCheckoutID = isBrowser
        ? window.localStorage.getItem("shopify_checkout_id")
        : null;

      const setCheckoutInState = (checkout) => {
        if (isBrowser) {
          window.localStorage.setItem("shopify_checkout_id", checkout.id);
        }

        if (mock && mock.act) {
          mock.act(() => {
            updateStore((prevState) => ({ ...prevState, checkout }));
          });
        } else {
          updateStore((prevState) => ({ ...prevState, checkout }));
        }
      };

      const createNewCheckout = () => store.client.checkout.create();
      const fetchCheckout = (id) => store.client.checkout.fetch(id);

      if (existingCheckoutID) {
        try {
          // const ts = Date.now();
          // console.log("before checkout fetch", ts);
          const checkout = await fetchCheckout(existingCheckoutID);
          // const ts2 = Date.now();
          // console.log("after checkout fetch", ts2, ts2 - ts);
          // Make sure this cart hasn’t already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          window.localStorage.setItem("shopify_checkout_id", null);
        }
      }

      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, [store.client.checkout]);

  // const prevAdding = usePrevious(store.adding);
  // useEffect(() => {
  //   if (prevAdding && !store.adding) {
  //     // window.location.href = "/cart";
  //   }
  // }, [store.adding]);

  return (
    <>
      <ShopifyContext.Provider
        value={{
          store,
          /**
           * add variant(s) to cart
           * @param variantIds single id or an array of ids
           * @param quantity quantity to be applied to _all_ ids
           * @returns {*}
           *
           * Note - this method actually updates the quantities
           * for variants in the cart. In other words, repeated calls
           * to 'add' variant X with quantity 1 will result in a cart
           * with quantity 1, not 1 for each time this method was called
           */
          addDiscount: (code, checkoutID, callback) => {
            store.client.checkout.addDiscount(checkoutID, code).then(callback);
          },
          removeDiscount: (checkoutID, callback) => {
            store.client.checkout.removeDiscount(checkoutID).then(callback);
          },
          addVariantToCart: (variantIds = [], quantity, customAttributes) => {
            let newVariantIds = variantIds;
            if (!Array.isArray(variantIds)) newVariantIds = [variantIds];
            if (variantIds.length === 0 || !quantity) {
              // console.error('At least one variant id and quantity are required.');
              return;
            }

            updateStore((prevState) => ({ ...prevState, adding: true }));

            const checkoutId = store.checkout.id;
            const lineItemsToUpdate = newVariantIds.map((variantId) => ({
              variantId,
              quantity: parseInt(quantity, 10),
              customAttributes,
            }));

            store.client.checkout
              .addLineItems(checkoutId, lineItemsToUpdate)
              .then((checkout) => {
                updateStore((prevState) => ({
                  ...prevState,
                  checkout,
                  adding: false,
                }));
              });
          },
          removeLineItem: (storeClient, checkoutID, lineItemID) =>
            storeClient.checkout
              .removeLineItems(checkoutID, [lineItemID])
              .then((res) => {
                updateStore((prevState) => ({ ...prevState, checkout: res }));
              }),
          updateLineItem: (
            storeClient,
            checkoutID,
            lineItemID,
            quantity,
            customAttributes,
          ) => {
            const lineItemsToUpdate = [
              {
                id: lineItemID,
                quantity: parseInt(quantity, 10),
                customAttributes,
              },
            ];

            return storeClient.checkout
              .updateLineItems(checkoutID, lineItemsToUpdate)
              .then((res) => {
                updateStore((prevState) => ({ ...prevState, checkout: res }));
              });
          },
          updateCheckout: (checkout) => {
            updateStore((prevState) => ({
              ...prevState,
              checkout,
            }));
          },
        }}>
        {children}
      </ShopifyContext.Provider>
    </>
  );
};

ShopifyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  mock: PropTypes.shape({
    client: PropTypes.objectOf(PropTypes.any),
    act: PropTypes.func,
  }),
};

ShopifyContextProvider.defaultProps = {
  mock: null,
};

export default ShopifyContextProvider;
